import type { UseFetchOptions } from "@vueuse/core";
const { BASE_URL } = useRuntimeConfig().public;
const auth = useCookie<AuthResponse>("auth");

export const requestConfig = {
  method: "GET",
  headers: {
    Authorization: `Bearer ${auth.value.access}`,
  },
} as UseFetchOptions;

export const getAudienceData = (id: string) => {
  return useFetch<{ status: string; results: AudienceResponseType }>(
    `${BASE_URL}/api/v2/audiences/${id}/`,
    requestConfig,
  );
};

export const getSegmentData = (id: string) => {
  return useFetch<SegmentType>(`${BASE_URL}/api/v2/segment/${id}/`, requestConfig);
};

export const getCampaignData = (id: string) => {
  return useFetch<CampaignResponseType>(`${BASE_URL}/api/v2/campaigns/${id}/`, requestConfig);
};

export const getRegions = () => {
  return useFetch<RegionResponseType[]>(`${BASE_URL}/api/v2/region/`, requestConfig);
};

export const getBrands = (listPlatforms = true) => {
  const brandsRequestConfig = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${auth.value.access}`,
    },
    body: {
      reports: false,
      list_platforms: listPlatforms,
    },
  } as UseFetchOptions;
  return useFetch<DropdownSelectionResponse>(`${BASE_URL}/api/v2/brands/`, brandsRequestConfig);
};

export const getPlatforms = <T>(body: any) => {
  const platformsRequestConfig = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${auth.value.access}`,
    },
    body,
  } as UseFetchOptions;
  return useFetch<T>(`${BASE_URL}/api/v2/platforms/`, platformsRequestConfig);
};

export const getAudienceFilters = (body: any) => {
  const brandsRequestConfig = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${auth.value.access}`,
    },
    body,
  } as UseFetchOptions;
  return useFetch<BuildAudienceFilterResponse>(
    `${BASE_URL}/api/v2/audiences/filters/`,
    brandsRequestConfig as UseFetchOptions,
  );
};

export const getAudienceActiveCount = (body: any) => {
  const brandsRequestConfig = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${auth.value.access}`,
    },
    body,
  } as UseFetchOptions;
  return useFetch<ActiveIdsCount>(
    `${BASE_URL}/api/v2/audiences/filter_audience_count/`,
    brandsRequestConfig as UseFetchOptions,
  );
};
